/* External dependencies */
import gql from 'graphql-tag';

export const getTransactionsQueueMetricsQuery = gql`
  query getTransactionsQueueMetrics {
    getTransactionsQueueMetrics {
      pay24InProgressMessageCount
      pay24PendingMessageCount
      demirBankInProgressMessageCount
      demirBankPendingMessageCount
    }
  }
`;
