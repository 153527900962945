/* Local dependencies */
import { TransactionsQueueMetricsQueueType } from './types';

export enum QueueMetricsActionTypes {
  QUEUE_METRICS_REQUEST = 'QUEUE_METRICS_REQUEST',
  QUEUE_METRICS_SUCCESS = 'QUEUE_METRICS_SUCCESS',
  QUEUE_METRICS_ERROR = 'QUEUE_METRICS_ERROR',
  QUEUE_METRICS_RESET_STATE = 'QUEUE_METRICS_RESET_STATE',
  QUEUE_METRICS_RESET_ERROR = 'QUEUE_METRICS_RESET_ERROR',
  REDRIVE_QUEUE_REQUEST = 'REDRIVE_QUEUE__REQUEST',
  REDRIVE_QUEUE_SUCCESS = 'REDRIVE_QUEUE_SUCCESS',
  REDRIVE_QUEUE_ERROR = 'REDRIVE_QUEUE_ERROR',
}

export interface QueueMetricsRequest {
  type: QueueMetricsActionTypes.QUEUE_METRICS_REQUEST;
}

export interface QueueMetricsSuccess {
  type: QueueMetricsActionTypes.QUEUE_METRICS_SUCCESS;
  values: TransactionsQueueMetricsResponse;
}

export interface TransactionsQueueMetricsResponse {
  pay24InProgressMessageCount?: string;
  pay24PendingMessageCount?: string;
  demirBankInProgressMessageCount?: string;
  demirBankPendingMessageCount?: string;
}

export interface QueueMetricsError {
  type: QueueMetricsActionTypes.QUEUE_METRICS_ERROR;
  error: Error;
}

export interface ResetQueueMetricsState {
  type: QueueMetricsActionTypes.QUEUE_METRICS_RESET_STATE;
}

export interface ResetQueueMetricsError {
  type: QueueMetricsActionTypes.QUEUE_METRICS_RESET_ERROR;
}

export interface RedriveQueueRequest {
  type: QueueMetricsActionTypes.REDRIVE_QUEUE_REQUEST;
  queue: TransactionsQueueMetricsQueueType;
}

export interface RedriveQueueSuccess {
  type: QueueMetricsActionTypes.REDRIVE_QUEUE_SUCCESS;
  values: any;
}

export interface RedriveQueueError {
  type: QueueMetricsActionTypes.REDRIVE_QUEUE_ERROR;
  error: Error;
}

export interface RedriveTransactionQueueResponse {
  id: string;
}

export type QueueMetricsAction =
  | QueueMetricsRequest
  | QueueMetricsSuccess
  | QueueMetricsError
  | ResetQueueMetricsState
  | ResetQueueMetricsError
  | RedriveQueueSuccess
  | RedriveQueueError
  | RedriveQueueRequest;

export function getQueueMetrics(): QueueMetricsRequest {
  return {
    type: QueueMetricsActionTypes.QUEUE_METRICS_REQUEST,
  };
}

export function getQueueMetricsSucceeded(values: TransactionsQueueMetricsResponse): QueueMetricsSuccess {
  return {
    type: QueueMetricsActionTypes.QUEUE_METRICS_SUCCESS,
    values,
  };
}

export function getQueueMetricsFailed(error: Error): QueueMetricsError {
  return {
    type: QueueMetricsActionTypes.QUEUE_METRICS_ERROR,
    error,
  };
}

export function resetQueueMetrics(): ResetQueueMetricsState {
  return {
    type: QueueMetricsActionTypes.QUEUE_METRICS_RESET_STATE,
  };
}

export function resetQueueMetricsError(): ResetQueueMetricsError {
  return {
    type: QueueMetricsActionTypes.QUEUE_METRICS_RESET_ERROR,
  };
}

export function redriveQueueRequest(queue: TransactionsQueueMetricsQueueType): RedriveQueueRequest {
  return {
    type: QueueMetricsActionTypes.REDRIVE_QUEUE_REQUEST,
    queue,
  };
}

export function redriveQueueSucceeded(values: RedriveTransactionQueueResponse): RedriveQueueSuccess {
  return {
    type: QueueMetricsActionTypes.REDRIVE_QUEUE_SUCCESS,
  };
}

export function redriveQueueFailed(error: Error): RedriveQueueError {
  return {
    type: QueueMetricsActionTypes.REDRIVE_QUEUE_ERROR,
    error,
  };
}
