/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { QueueMetricsAction, QueueMetricsActionTypes, RedriveTransactionQueueResponse } from './action';

export interface QueueMetricsState {
  error?: Error;
  isSuccess: boolean;
  loading?: boolean;
  redriveQueueSuccess?: RedriveTransactionQueueResponse;
  isRedriveSuccess: boolean;
  pay24InProgressMessageCount: string;
  pay24PendingMessageCount: string;
  demirBankInProgressMessageCount: string;
  demirBankPendingMessageCount: string;
  isRedriveLoading: boolean;
  queue?: string;
}

export const initialQueueMetricsState: QueueMetricsState = {
  isSuccess: false,
  pay24InProgressMessageCount: '',
  pay24PendingMessageCount: '',
  demirBankInProgressMessageCount: '',
  demirBankPendingMessageCount: '',
  loading: false,
  isRedriveSuccess: false,
  isRedriveLoading: false,
};

export default function transactionsQueueMetrics(state = initialQueueMetricsState, action: QueueMetricsAction) {
  switch (action.type) {
    case QueueMetricsActionTypes.QUEUE_METRICS_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        isSuccess: { $set: false },
      });

    case QueueMetricsActionTypes.QUEUE_METRICS_SUCCESS:
      const {
        pay24InProgressMessageCount,
        pay24PendingMessageCount,
        demirBankInProgressMessageCount,
        demirBankPendingMessageCount,
      } = action.values;

      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
        pay24InProgressMessageCount: { $set: pay24InProgressMessageCount },
        pay24PendingMessageCount: { $set: pay24PendingMessageCount },
        demirBankInProgressMessageCount: { $set: demirBankInProgressMessageCount },
        demirBankPendingMessageCount: { $set: demirBankPendingMessageCount },
      });

    case QueueMetricsActionTypes.QUEUE_METRICS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case QueueMetricsActionTypes.QUEUE_METRICS_RESET_STATE:
      return update(state, { $set: initialQueueMetricsState });

    case QueueMetricsActionTypes.QUEUE_METRICS_RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case QueueMetricsActionTypes.REDRIVE_QUEUE_REQUEST:
      return update(state, {
        $unset: ['error'],
        queue: { $set: action.queue },
        isRedriveSuccess: { $set: false },
        isRedriveLoading: { $set: true },
      });

    case QueueMetricsActionTypes.REDRIVE_QUEUE_SUCCESS:
      return update(state, {
        redriveQueueSuccess: { $set: action.values },
        isRedriveSuccess: { $set: true },
        isRedriveLoading: { $set: false },
      });

    case QueueMetricsActionTypes.REDRIVE_QUEUE_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
        isRedriveSuccess: { $set: false },
        isRedriveLoading: { $set: false },
      });

    default:
      return state;
  }
}
