/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import {
  getQueueMetricsFailed,
  getQueueMetricsSucceeded,
  QueueMetricsAction,
  QueueMetricsActionTypes,
  QueueMetricsSuccess,
  redriveQueueFailed,
  RedriveQueueRequest,
  redriveQueueSucceeded,
  RedriveQueueSuccess,
} from './action';
import { redriveTransactionQueueMutation } from './mutation';
import { getTransactionsQueueMetricsQuery } from './query';
import { getClient } from '../../../../clients/demirbank';

export default function transactionsQueueMetricsEpic(action$) {
  return action$.pipe(
    filter((action: QueueMetricsAction) => action.type === QueueMetricsActionTypes.QUEUE_METRICS_REQUEST),
    switchMap((action: QueueMetricsAction) =>
      transactionsQueueMetrics().then(getQueueMetricsSucceeded).catch(getQueueMetricsFailed),
    ),
  );
}

export async function transactionsQueueMetrics(): Promise<QueueMetricsSuccess> {
  const graphQLClient = await getClient();

  const {
    data: { getTransactionsQueueMetrics },
  } = await graphQLClient.query({
    query: getTransactionsQueueMetricsQuery,
  });

  return getTransactionsQueueMetrics;
}

export function redrivePendingQueueMetricsEpic(action$) {
  return action$.pipe(
    filter((action: QueueMetricsAction) => action.type === QueueMetricsActionTypes.REDRIVE_QUEUE_REQUEST),
    switchMap((action: RedriveQueueRequest) =>
      redrivePendingQueueMetrics(action).then(redriveQueueSucceeded).catch(redriveQueueFailed),
    ),
  );
}

export async function redrivePendingQueueMetrics({ queue }): Promise<RedriveQueueSuccess> {
  const graphQLClient = await getClient();
  const {
    data: { redriveTransactionQueue },
  } = await graphQLClient.mutate({
    mutation: redriveTransactionQueueMutation,
    variables: {
      input: {
        queueType: queue,
      },
    },
  });

  return redriveTransactionQueue;
}
